import React from "react"
import Container from "react-bootstrap/Container"
import { useTranslation } from "react-i18next"

/* Images */
import NavBarLogo from "../images/logo.svg"

/* Components */
import Wrapper from "../components/wrapper/wrapper"
import SEO from "../components/seo/seo"
import NavBar from "../components/navbar/navbar"
import Main from "../components/main/main"
import ErrorMessage from "../components/error-message/error-message"
import Footer from "../components/footer/footer"

export default function NotFound({ location }) {
  const { t } = useTranslation()

  const PageTitle = t("pages.404.title")
  const PageDescription = t("meta.description")

  return (
    <Wrapper>
      <SEO title={PageTitle} description={PageDescription} />
      <NavBar classVariant="navBarDefault" logo={NavBarLogo} />
      <Main>
        <Container>
          <ErrorMessage
            title={t("pages.404.hero.title")}
            subtitle={t("pages.404.hero.subtitle")}
            text={t("pages.404.hero.text")}
          />
        </Container>
      </Main>
      <Footer />
    </Wrapper>
  )
}
