import React from "react"

import styles from "./error-message.module.css"

export default function ErrorMessage({ title, subtitle, text }) {
  return (
    <div className="row">
      <div className="col-12">
        <h2 className={`${styles.errorTitle} text-center`}>{title}</h2>
        <h4 className={`${styles.errorSubtitle} text-center`}>{subtitle}</h4>
        <p className={`${styles.errorText} text-center`}>{text}</p>
      </div>
    </div>
  )
}
